import React from "react";
import { Link } from "react-router-dom"
import Tastes from "../../components/Tastes";
import Top from "./img/coffe-top.png"
import CoffeCopy from "./img/coffe-copy.png"
import Bottle from "./img/coffe-bottle.png"
import Video from "../../../video/kawa_pij_odpowiedzialnie.mp4"
import "./style.scss"

export default function CoffePage() {

  return (
    <section className="coffe-page">
      <video className="img-top" src={ Video } autoPlay muted playsInline poster={ Top } onEnded={ (vid) => {
        document.querySelector('.coffe-content').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        vid.target.loop = true
        vid.target.play()
      } }/>
      {/* <img className="img-top" src={ Top } alt="Zasmakuj. Kawa z nutą brandy."/> */}
      <div className="coffe-content">
        <div className="content-inner">
          <div className="bottle">
            <img src={ Bottle } alt="Saska Wiśnia z nutą rumu"/>
          </div>
          <div className="text">
            <img src={ CoffeCopy } alt="Saska. Zasmakuj."/>
            <h1>Kawa<small>z nutą brandy</small></h1>
            <p>Witamy w&nbsp;świecie aromatycznej i&nbsp;pobudzającej kubki smakowe, kawowej słodkości, którą zbalansowaliśmy odrobiną intensywnej nuty brandy.</p>
          </div>
        </div>
        <div className="buttons">
          <Link to="/wisnia" className="btn">Odkryj soczystą wiśnię</Link>
          <button className="btn" onClick={ () => {
            document.querySelector('.tastes').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
          } }>Odkryj rodzinę smaków</button>
        </div>
      </div>
      <Tastes/>
    </section>
  );
}
