import React, { useState } from "react"
import "./style.scss"

export default function Cookies() {
  const getCookie = (sName) => {
    var oCrumbles = document.cookie.split(';');
    for(var i=0; i<oCrumbles.length;i++){
        var oPair= oCrumbles[i].split('=');
        var sKey = decodeURIComponent(oPair[0].trim());
        var sValue = oPair.length>1 ? oPair[1] : '';
        if(sKey === sName) {
            return decodeURIComponent(sValue);
        }
    }
    return '';
  }

  const setCookie = (sName, sValue, options) => {
      var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
      if (options && options instanceof Date) {
          options = {
              expires: options
          };
      }
      if (options && typeof options === 'object') {
          if (options.expires) {
              sCookie += '; expires=' + options.expires.toGMTString();
          }
          if (options.path) {
              sCookie += '; path=' + options.path.toString();
          }
          if (options.domain) {
              sCookie += '; domain=' + options.domain.toString();
          }
          if (options.secure) {
              sCookie += '; secure';
          }
      }
      document.cookie= sCookie;
  }

  const [showCookies, setShowCookies] = useState(getCookie("cookieAccepted") === "true" ? false : true)

  const acceptCookies = () => {
    setCookie("cookieAccepted", "true")
    setShowCookies(false)
    window.location.reload()
  }

  return (
    <>
    { showCookies ? <div className="cookies">
        <div className="container">
          <h2>Cookies</h2>
          <p>Korzystanie ze strony bez zmiany ustawień przeglądarki oznacza zgodę na zapisywanie plików cookies na Twoim urządzeniu i na ich wykorzystanie w celach analitycznych, bezpieczeństwa oraz zapewnienia wygody korzystania ze strony.<br/>Przez odpowiednią zmianę ustawień przeglądatki możesz usunąć już zapisane pliki cookies lub zablokować zapisywanie nowych plików cookies.<br/>Więcej informacji znajdziesz w Polityce prywatności Grupy Stock Spirits: <a href="/assets/POLITYKA_PRYWATNOSCI_GRUPY_STOCK_SPIRITS.pdf" target="_blank" rel="noopener noreferrer">link</a>.</p>
          <div className="buttons">
            <button className="btn accept" onClick={ () => { acceptCookies() } }>Akceptuję</button>
            <button className="btn" onClick={ () => { setShowCookies(false) } }>Odrzucam</button>
          </div>
        </div>
    </div> : null }
    </>
  );
}
