import React, { useState } from "react"
import { useLocation } from "react-router";
import "./style.scss"

export default function AgeGate() {
  const [showAgeGate, setShowAgeGate] = useState(sessionStorage.getItem("ageGate") === "false" ? false : true)
  const { pathname } = useLocation()
  const taste = pathname === "/kawa" ? "coffe" : "cherry"
  const [ageVerified, setAgeVerified] = useState(null)
  const [agreement, setAgreement] = useState(null)
  const [showErrors, setShowErrors] = useState(false)

  const getCookie = (sName) => {
    var oCrumbles = document.cookie.split(';');
    for(var i=0; i<oCrumbles.length;i++){
        var oPair= oCrumbles[i].split('=');
        var sKey = decodeURIComponent(oPair[0].trim());
        var sValue = oPair.length>1 ? oPair[1] : '';
        if(sKey === sName) {
            return decodeURIComponent(sValue);
        }
    }
    return '';
  }

  return (
    <>
    { showAgeGate ? <div className={ "age-gate " + taste }>
        <div className="container">
        <h2>Czy masz ukończone 18 lat?</h2>
          <div className="buttons">
            <button className={ ageVerified === true ? "btn active" : "btn" } onClick={ () => { setAgeVerified(true) } }>Tak</button>
            <button className={ ageVerified === false ? "btn active" : "btn" } onClick={ () => { setAgeVerified(false) } }>Nie</button>
          </div>
          { showErrors && ageVerified !== true ? <p className="error">Musisz mieć ukończone 18 lat</p> : null }
          <h2>Wyrażam zgodę na wyświetlenie reklamy dotyczącej alkoholu.</h2>
          <div className="buttons second">
            <button className={ agreement === true ? "btn active" : "btn" } onClick={ () => { setAgreement(true) } }>Tak</button>
            <button className={ agreement === false ? "btn active" : "btn" } onClick={ () => { setAgreement(false) } }>Nie</button>
          </div>
          { showErrors && agreement !== true ? <p className="error second">Zgoda wymagana</p> : null }
          <button className="btn next" onClick={ () => {
            if(ageVerified && agreement) {
              setShowAgeGate(false)
              if(getCookie("cookieAccepted") === "true") sessionStorage.setItem("ageGate", false)
            } else {
              setShowErrors(true)
            }
          } }>Dalej</button>
        </div>
    </div> : null }
    </>
  );
}
