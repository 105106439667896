import React from "react";
import { Link } from "react-router-dom"
import Tastes from "../../components/Tastes";
import Top from "./img/cherry-top.jpg"
import CherryCopy from "./img/cherry-copy.png"
import Bottle from "./img/cherry-bottle.png"
import "./style.scss"

export default function CherryPage() {

  return (
    <section className="cherry-page">
      <img className="img-top"  src={ Top } alt="Zasmakuj w tym co najlepsze."/>
      <div className="cherry-content">
        <div className="content-inner">
          <div className="bottle">
            <img src={ Bottle } alt="Saska Wiśnia z nutą rumu"/>
          </div>
          <div className="text">
            <img src={ CherryCopy } alt="Saska. Zasmakuj."/>
            <h1>Wiśnia<small>z nutą rumu</small></h1>
            <p>Miło nam, że udało Ci się sprawdzić, co kryje się za prawdziwą głębią smaku, zaklętą w&nbsp;naszej soczystej Wiśni z&nbsp;odrobiną charakternej, rumowej nutki.</p>
          </div>
        </div>
        <div className="buttons">
          <Link to="/kawa" className="btn">Odkryj aromatyczną kawę</Link>
          <button className="btn" onClick={ () => {
            document.querySelector('.tastes').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
          } }>Odkryj rodzinę smaków</button>
        </div>
      </div>
      <Tastes/>
    </section>
  );
}
