import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ImagesLoaded from 'react-images-loaded';
import Footer from "./components/Footer";
import AgeGate from "./components/AgeGate";
import Cookies from "./components/Cookies";
import CoffePage from "./pages/CoffePage"
import CherryPage from "./pages/CherryPage"

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if(document.querySelector(".app")) document.querySelector(".app").scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Transition() {
  let location = useLocation();
  const [preloader, setPreloader] = useState(true)
  const [percent, setPercent] = useState(0)

  return (
    <ImagesLoaded
        className={'app'}
        onProgress={(instance) => {
          const imageLength = instance.images.length
          const progress = instance.progressedCount
          const step = 100 / imageLength
          setPercent(parseInt(step * progress))
        }}
        done={() => {
          setPreloader(false)
        }}
        background={true}
      >
        <div className="page-holder">
        <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="page"
              timeout={{ enter: 250, exit: 250 }}
            >
              <div>
                <Routes location={location}>
                  <Route path={`${process.env.PUBLIC_URL}/`} exact element={<CherryPage/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/kawa`} exact element={<CoffePage/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/wisnia`} exact element={<CherryPage/>}/>
                </Routes>
                <Footer/>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <AgeGate/>
        <Cookies/>
        { preloader ? <div className={ "preloader " + (location.pathname === "/kawa" ? "coffe" : "cherry") }>
          <p className="percentage">{percent}%</p>
        </div> : null }
    </ImagesLoaded>
  );
}

export default function MainView() {
  return (
    <Router>
      <ScrollToTop />
      <Transition />
    </Router>
  );
}
