import CzystaWodka from "../../img/tastes/czysta-wodka.png"
import Crema from "../../img/tastes/crema.png"
import CzerwonaPorzeczka from "../../img/tastes/czerwona-porzeczka.png"
import OrzechLaskowy from "../../img/tastes/orzech-laskowy.png"
import Pigwa from "../../img/tastes/pigwa.png"
import SliwkaWegierka from "../../img/tastes/sliwka-wegierka.png"
import WisniaZNutaRumu from "../../img/tastes/wisnia-z-nuta-rumu.png"
import SlonyKarmel from "../../img/tastes/slony-karmel.png"

export const tastes = [
    {
        name: "Czysta<br/><span>wódka</span>",
        alt: "Czysta wódka",
        img: CzystaWodka,
        class: "czysta-wodka"
    },
    {
        name: "Pigwa",
        alt: "Pigwa",
        img: Pigwa,
        class: "pigwa"
    },
    {
        name: "Orzech laskowy<br/><span>z nutą karmelu</span>",
        alt: "Orzech laskowy z nutą karmelu",
        img: OrzechLaskowy,
        class: "orzech-laskowy"
    },
    {
        name: "Wiśnia<br/><span>z nutą rumu</span>",
        alt: "Wiśnia z nutą rumu",
        img: WisniaZNutaRumu,
        class: "wisnia-z-nuta-rumu"
    },
    {
        name: "Śliwka<br/><span>węgierka</span>",
        alt: "Śliwka węgierka",
        img: SliwkaWegierka,
        class: "sliwka-wegierka"
    },
    {
        name: "Crema",
        alt: "Crema",
        img: Crema,
        class: "crema"
    },
    {
        name: "Czerwona<br/><span>porzeczka</span>",
        alt: "Czerwona porzeczka",
        img: CzerwonaPorzeczka,
        class: "czerwona-porzeczka"
    },
    {
        name: "Nowość<br/><span>słony karmel</span>",
        alt: "Słony karmel",
        img: SlonyKarmel,
        class: "slony-karmel"
    }
]