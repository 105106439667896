import React from "react"
import "./style.scss"

export default function Footer() {

  return (
    <footer>
      <a href="/assets/POLITYKA_PRYWATNOSCI_GRUPY_STOCK_SPIRITS.pdf" target="_blank" rel="noopener noreferrer">Polityka prywatności</a>
    </footer>
  );
}
