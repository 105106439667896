import React from "react"
import { Swiper, SwiperSlide } from "swiper/react";
import { tastes } from "../../data/tastes"
import "swiper/css";
import "swiper/css/pagination"
import "./style.scss"

import SwiperCore, {
  Navigation
} from 'swiper';

SwiperCore.use([Navigation]);

export default function Tastes() {

  return (
    <div className="tastes">
        <h2>Rodzina smaków</h2>
        <Swiper pagination={true} className="swiper-tastes" navigation={true} loop={true} slidesPerView={1}>
          {tastes.map((element, index) => (
            <SwiperSlide key={index}>
              <div key={index} className={ "taste " + element.class }>
                <div className="img">
                  <img src={ element.img } alt={ element.alt }/>
                </div>
                <div className="content">
                  <div className={ "name " + element.class } dangerouslySetInnerHTML={{ __html: element.name }}></div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <a className="drink-responsibly" href="https://pijodpowiedzialnie.pl" target="_blank" rel="noopener noreferrer">pijodpowiedzialnie.pl</a>
    </div>
  );
}
